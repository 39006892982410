// Able to store the header names for all the tables used inside the codebase

export const transactionHeaderNames = [
  { name: "ID", value: "_id", helpText: "Record ID" },
  { name: "VIN", value: "vin", helpText: "Vehicle Identification Number" },
  {
    name: "License Plate",
    value: "licensePlate",
    helpText: "License Plate Number",
  },
  {
    name: "State",
    value: "licensePlateState",
    helpText: "License Plate State",
  },
  {
    name: "Passenger Count",
    value: "passengersCount",
    helpText:
      "Passenger occupancy count (including driver).  Used for HOV/HOT eligibility",
  },
  { name: "Vehicle Class", value: "className", helpText: "Number of Axels" },
  { name: "Plaza Name", value: "plazaName", helpText: "Toll Plaza Name" },
  { name: "Plaza ID", value: "externalPlazaId", helpText: "Toll Plaza ID" },
  {
    name: "Status",
    value: "status",
    helpText: "Status of the transaction: Complete, Incomplete, Error",
  },
  {
    name: "Toll Amount",
    value: "tollAmount",
    helpText: "Tolling charge displayed in the vehicle",
  },
  {
    name: "Charge Type",
    value: "chargeType",
    helpText:
      "Toll transaction charges applied based on the configurations below:  AVI (Toll tag), Plate (License Plate), Cash, Negotiated, Equity",
  },
  { name: "Agency", value: "agencyName", helpText: "Toll Agency Name" },
  {
    name: "Timezone",
    value: "timeZoneName",
    helpText: "Timezone derived from the vehicle lat/long",
  },
  { name: "Toll Time UTC", value: "tollDateTime", helpText: "Toll Time UTC" },
  {
    name: "Toll Time Local",
    value: "tollDateTimeLocal",
    helpText: "Toll Time Local",
  },
];

export const rucHeaderNames = [
  { name: "ID", value: "_id", helpText: "System Record ID" },
  { name: "VIN", value: "deviceid", helpText: "Vehicle Identification Number" },
  {
    name: "Geofence ID",
    value: "geofenceId",
    helpText: "Geofence ID created by the database",
  },
  {
    name: "Geofence Name",
    value: "geofenceName",
    helpText: "Name of the RUC Geofence",
  },
  { name: "Entry Time UTC", value: "entryTime", helpText: "Entry Time UTC" },
  {
    name: "Entry Time Local",
    value: "entryTimeLocal",
    helpText: "Entry Time Local",
  },
  { name: "Exit Time UTC", value: "exitTime", helpText: "Exit Time UTC" },
  {
    name: "Exit Time Local",
    value: "exitTimeLocal",
    helpText: "Exit Time Local",
  },
  {
    name: "Odometer (KM)",
    value: "totalOdometer",
    helpText: "Odometer in Kilometers",
  },
  {
    name: "Type",
    value: "type",
    helpText: "Service type: RUC, Toll, VMPass",
  },
];

export const gnssTollHeaderNames = [
  { name: "ID", value: "_id" },
  { name: "VIN", value: "vin" },
  { name: "Vehicle Class", value: "vehicleClass" },
  { name: "License Plate", value: "licensePlate" },
  { name: "State", value: "licensePlateState" },
  { name: "Toll Amount", value: "tollAmount" },
  { name: "Toll Time UTC", value: "tollDateTime" },
  { name: "Toll Time Local", value: "tollDateTimeLocal" },
  { name: "Agency", value: "agency" },
  { name: "Plaza", value: "plaza" },
  { name: "Plaza ID", value: "externalPlazaId" },
  { name: "Is Registered", value: "isRegisteredAccount" },
  { name: "Passenger Count", value: "passengersCount" },
  { name: "Timezone", value: "timeZoneName" },
];

export const agencyTollHeaderNames = [
  { name: "_id", value: "_id" },
  { name: "iBt_Queue_Id", value: "iBt_Queue_Id" },
  { name: "aET_TRAN_NUMBER", value: "aET_TRAN_NUMBER" },
  { name: "violation_number", value: "violation_number" },
  { name: "plaza", value: "plaza" },
  { name: "lane", value: "lane" },
  { name: "tran_Time", value: "tran_Time" },
  { name: "lane_Tran", value: "lane_Tran" },
  { name: "number_Of_Image", value: "number_Of_Images" },
  { name: "or_Ocr_Plate", value: "or_Ocr_Plate" },
  { name: "or_Ocr_State", value: "or_Ocr_State" },
  { name: "or_Ocr_Confidence", value: "or_Ocr_Confidence" },
  { name: "or_Ocr_Plate_Type", value: "or_Ocr_Plate_Type" },
  { name: "agency_Created_Time", value: "agency_Created_Time" },
  { name: "bo_Ocr_Confidence", value: "bo_Ocr_Confidence" },
  { name: "bo_Ocr_Plate", value: "bo_Ocr_Plate" },
  { name: "bo_Ocr_State", value: "bo_Ocr_State" },
  { name: "bo_Ocr_Plate_Type", value: "bo_Ocr_Plate_Type" },
  { name: "fileName", value: "fileName" },
  { name: "processed_Time", value: "processed_Time" },
  { name: "agencyName", value: "agencyName" },
  { name: "timeZone", value: "timeZoneName" },
  { name: "gnssTollActivityId", value: "gnssTollActivityId" },
  { name: "tran_Time_Local", value: "tran_Time_Local" },
];

export const vehicleHeaders = [
  { name: "Vin", value: "vin", helpText: "Vehicle Identification Number" },
  {
    name: "Nickname",
    value: "nickName",
    helpText: "Vehicle Nickname if used ",
  },
  {
    name: "Provider",
    value: "provider",
    helpText: "Provider data source: Provider, DRP, Simulator",
  },
  {
    name: "Status",
    value: "statusName",
    helpText: "Vehicle activated with the provider and ConnectOne",
  },
  {
    name: "Status Notes",
    value: "statusNotes",
    helpText: "Incase an error ocurred while enrolling it will be shown here",
  },
  {
    name: "License Plate",
    value: "licensePlate",
    helpText: "License Plate Number",
  },
  {
    name: "License Plate State",
    value: "licensePlateState",
    helpText: "License Plate State",
  },
  { name: "Plate Type", value: "plateType", helpText: "Vehicle Plate Type" },
  { name: "Class", value: "className", helpText: " Vehicle class type" },
  {
    name: "Fuel Type",
    value: "fuelType",
    helpText: "Vehicle Type fuel: Gas, Electric, Hybrid",
  },
  { name: "Year", value: "year", helpText: "Vehicle year" },
  { name: "Make", value: "make", helpText: "Vehicle make" },
  { name: "Model", value: "model", helpText: "Vehicle model" },
  { name: "Axles", value: "axles", helpText: "Number of vehicle axles" },
  { name: "Tires", value: "tires", helpText: "Number of vehicle tires" },
  { name: "Device Type", value: "deviceType", helpText: "" },
  { name: "Toll Transponder ID", value: "tollTransponderId", helpText: "" },
  { name: "Fuel Charge ID", value: "fuelChargeId", helpText: "" },
  {
    name: "OBD2ID",
    value: "obd2ID",
    helpText: "On-board diagnostics Parameter ID",
  },
  {
    name: "Equity",
    value: "equity",
    helpText: "Vehicle enrolled on equity program",
  },
  {
    name: "Receive Messages",
    parentValue: "communicationPreferences",
    value: "receiveInfotainmentMessages",
    helpText: "",
  },
  {
    name: "Plate Start Effective Date",
    value: "licensePlateStartEffectiveDate",
    helpText: "",
  },
  {
    name: "Plate End Effective Date",
    value: "licensePlateEndEffectiveDate",
    helpText: "",
  },
  {
    name: "Vehicle Start Effective Date",
    value: "vehicleStartEffectiveDate",
    helpText: "",
  },
  {
    name: "Vehicle End Effective Date",
    value: "vehicleEndEffectiveDate",
    helpText: "",
  },
  {
    name: "Created Date",
    value: "createDate",
    helpText: "Date when vehicle was added to the system",
  },
  {
    name: "Last Updated Date",
    value: "lastUpdateDate",
    helpText: "Date when was last updated",
  },
  {
    name: "Last Updated By",
    value: "lastUpdatedBy",
    helpText: "User who last updated",
  },
];

export const vehicleActivityHeaders = [
  { name: "Vin", value: "vin", helpText: "Vehicle Identification Number" },
  {
    name: "Nickname",
    value: "nickName",
    helpText: "Vehicle Nickname if used",
  },
  {
    name: "Status",
    value: "statusName",
    helpText: "Vehicle activated with the provider and ConnectOne",
  },
  {
    name: "License Plate",
    value: "licensePlate",
    helpText: "License Plate Number",
  },
  {
    name: "License Plate State",
    value: "licensePlateState",
    helpText: "License Plate State",
  },
  {
    name: "Latest Reported Activity",
    value: "latestReportedActivity",
    helpText: "Last vehicle trace received from the provider",
  },
  {
    name: "Activation Date",
    value: "activationDate",
    helpText: "Date the vehicle was activated in ConnectOne.",
  },
];

export const vehicleMessagesHeaders = [
  { name: "ID", value: "_id", helpText: "System Record ID" },
  { name: "Vin", value: "vin", helpText: "Vehicle Identification Number" },
  {
    name: "Provider",
    value: "provider",
    helpText: "Provider data source: Provider, DRP, Simulator",
  },
  {
    name: "TimeZone",
    value: "timeZoneName",
    helpText: "Timezone derived based from the vehicle lat/long",
  },
  {
    name: "Local Timestamp",
    value: "localTimeStamp",
    helpText: "Local Time the vehicle recorded the transaction",
  },
  {
    name: "UTC Timestamp",
    value: "requestDateTime",
    helpText: "UTC Time the vehicle recorded the transaction",
  },
  { name: "Message Type", value: "messageType", helpText: "" },
  { name: "Message", value: "message", helpText: "" },
  {
    name: "Status",
    value: "requestStatus",
    helpText: "Status of the outbound message: Ok/Bad request/Error/Forbidden",
  },
  { name: "Status Reason", value: "exceptionDetails", helpText: "" },
];

export const VinLookupHeaders = [
  { name: "Vin", value: "vin" },
  { name: "Nickname", value: "nickName" },
  { name: "License Plate", value: "licensePlate" },
  { name: "License Plate State", value: "licensePlateState" },
];

export const ServicesHeaders = [
  { name: "Name", value: "name", helpText: "Service Name" },
  {
    name: "Description",
    value: "description",
    helpText: "Description of service",
  },
  { name: "Status", value: "status", helpText: "Status of the service" },
  {
    name: "Created Date",
    value: "createdDate",
    helpText: "Date of service created",
  },
  {
    name: "Last Updated Date",
    value: "lastupdateDate",
    helpText: "Date of last time service was updated",
  },
  {
    name: "Last Updated By",
    value: "modifiedBy",
    helpText: "User who last updated the service",
  },
];

export const LocationHeaders = [
  { name: "Plaza Name", value: "name", helpText: "Plaza(Location) Name" },
  { name: "Plaza ID", value: "plazaId", helpText: "Plaza(Location) unique ID" },
  {
    name: "Agency",
    value: "agencyName",
    helpText: "Agency to who the plaza belongs to",
  },
  {
    name: "State",
    value: "agencyState",
    helpText: "State where the plaza is located",
  },
  {
    name: "Road Name",
    value: "roadName",
    helpText: "Name of the road where the plaza is located",
  },
  {
    name: "Location Type",
    value: "locationType",
    helpText: "Type of plaza: RUC, Toll, VMPass",
  },
  {
    name: "Status",
    value: "statusName",
    helpText: "Status of the plaza: Active or Inactive",
  },
  {
    name: "Verified",
    value: "verified",
    helpText: "If the plaza has been manually verified",
  },
  { name: "Notes", value: "notes", helpText: "Notes added by user" },
];

export const GeofenceHeaders = [
  { name: "Agency", value: "agencyName" },
  { name: "PlazaID", value: "plazaId" },
  { name: "PlazaName", value: "name" },
  { name: "RoadName", value: "roadName" },
  { name: "LocationType", value: "locationType" },
  { name: "LocationSubType", value: "locationSubType" },
  { name: "Long", value: "longitude" },
  { name: "Lat", value: "latitude" },
  { name: "Width", value: "width" },
  { name: "Height", value: "height" },
  { name: "Course", value: "course" },
  { name: "Notes", value: "notes" },
];

export const RatesHeaders = [
  { name: "Passengers", value: "passengersCount", required: true },
  { name: "Transponder Rate", value: "avi", required: true },
  { name: "Plate Rate", value: "plateRead", required: true },
  { name: "Cash Rate", value: "cashRate" },
  { name: "Negotiated Rate", value: "negotiatedRate" },
  { name: "HOV Rate", value: "hovRate" },
  { name: "Equity Rate", value: "equityRate" },
];

// For the vehicle traces report page
export const vehicleTracesReportHeaderNames = [
  {
    name: "Action",
    value: "actionButton",
    exportable: false,
    helpText: "This action will show you the trace in the map",
  },
  { name: "ID", value: "_id", helpText: "System ID" },
  { name: "VIN", value: "vin", helpText: "Vehicle Identification Number" },
  { name: "Plate", value: "licensePlate", helpText: "License Plate Number" },
  {
    name: "Plate State",
    value: "licensePlateState",
    helpText: "License Plate State",
  },
  { name: "Nickname", value: "nickName", helpText: "Vehicle Nickname" },
  {
    name: "Data Source",
    value: "dataSource",
    helpText: "Possible sources: Provider/DRP/Simulator",
  },
  { name: "Latitude", value: "latitude", helpText: "Latitude" },
  { name: "Longitude", value: "longitude", helpText: "Longitude" },
  {
    name: "Timezone",
    value: "timeZoneName",
    helpText: "Timezone derived based from the vehicle lat/long",
  },
  {
    name: "Local Timestamp",
    value: "timeStampLocal",
    helpText: "Local Time the vehicle recorded the transaction",
  },
  {
    name: "Local Received Timestamp",
    value: "timeStampTraceReceivedLocal",
    helpText: "Local time stamp for the trace received by ConnectOne",
  },
  {
    name: "Latency",
    value: "latency",
    helpText:
      "Time difference when the vehicle recorded the activity vs the time it was received by ConnectOne",
  },
  {
    name: "Tolling Speed",
    value: "tollingSpeed",
    helpText: "Fast mode or Slow Mode",
  },
  {
    name: "Speedometer",
    value: "speedometer",
    helpText: "Vehicle speed in kilometers per hour",
  },
  { name: "Odometer", value: "odometer", helpText: "Odometer in kilometers" },
  {
    name: "Passenger Present",
    value: "passengerPresent",
    helpText:
      "Passenger occupancy count (excluding driver).  Used for HOV/HOT eligibility",
  },
  {
    name: "Course",
    value: "course",
    helpText: "Course of travel in degrees. 0-359",
  },
  { name: "Altitude", value: "altitude", helpText: "Vehicle Altitude" },
  {
    name: "Horizontal Accuracy",
    value: "horizontalAccuracy",
    helpText:
      "GPS accuracy statistic that is sent by the hardware device to help determine accurate readings for use by the system",
  },
  {
    name: "Device Type",
    value: "deviceName",
    helpText: "Hardware Name: R1, VP4R, Etc.",
  },
  {
    name: "Connection Status",
    value: "connectionStatus",
    helpText:
      "True/False flag to indicate if the vehicle had a connection with Stellantis at the time the vehicle traces/transaction were recorded on the vehilce",
  },
  {
    name: "Processed Status",
    value: "processedStatus",
    helpText: "If the transaction has been processed",
  },
  { name: "Vehicle State", value: "vehicleState", helpText: "Ignition On/Off" },
  {
    name: "UTC Timestamp",
    value: "timeStampUTC",
    helpText: "UTC Time the vehicle recorded the transaction",
  },
  {
    name: "UTC Received Timestamp",
    value: "timeStampTraceReceived",
    helpText: "UTC Time when received by ConnectOne",
  },
];

// For the vehicle trace on map
export const vehicleTraceHeaderNames = [
  { name: "VIN", value: "vin" },
  { name: "Plate", value: "licensePlate" },
  { name: "Plate State", value: "licensePlateState" },
  { name: "Nickname", value: "nickName" },
  { name: "Data Source", value: "dataSource" },
  { name: "Latitude", value: "latitude" },
  { name: "Longitude", value: "longitude" },
  { name: "Timezone", value: "timeZoneName" },
  { name: "Local Timestamp", value: "timeStampLocal" },
  { name: "Local Received Timestamp", value: "timeStampTraceReceivedLocal" },
  { name: "Latency", value: "latency" },
  { name: "Tolling Speed", value: "tollingSpeed" },
  { name: "Speedometer", value: "speedometer" },
  { name: "Odometer", value: "odometer" },
  { name: "Passenger Present", value: "passengerPresent" },
  { name: "Course", value: "course" },
  { name: "Altitude", value: "altitude" },
  { name: "Horizontal Accuracy", value: "horizontalAccuracy" },
  { name: "Device Type", value: "deviceName" },
  { name: "Connection Status", value: "connectionStatus" },
  { name: "Processed Status", value: "processedStatus" },
  { name: "Vehicle State", value: "vehicleState" },
  { name: "UTC Timestamp", value: "timeStampUTC" },
  { name: "UTC Received Timestamp", value: "timeStampTraceReceived" },
  { name: "ID", value: "_id" },
];

export const rateHeaderNames = [
  { name: "Agency", value: "agencyName", helpText: "Toll Agency Name" },
  { name: "State", value: "state", helpText: "State" },
  { name: "Location Name", value: "name", helpText: "Toll Plaza Name" },
  { name: "Road Name", value: "roadName", helpText: "Road Name" },
  { name: "Vehicle Class", value: "vehicleClass", helpText: "Vehicle Class" },
  {
    name: "Day Of Week Start",
    value: "dayOfWeekStart",
    helpText: "Start day of the week",
  },
  {
    name: "Time Of Day Start",
    value: "timeOfDayStart",
    helpText: "Time of the day when rate charges start",
  },
  {
    name: "Day Of Week End",
    value: "dayOfWeekEnd",
    helpText: "End day of the week",
  },
  {
    name: "Time Of Day End",
    value: "timeOfDayEnd",
    helpText: "Time of the day when rate charges end",
  },
  {
    name: "Passenger Count",
    value: "passengersCount",
    helpText:
      "Passenger occupancy count (including driver).  Used for HOV/HOT eligibility",
  },
  {
    name: "Transponder Rate",
    value: "avi",
    helpText: "Rates used if the vehicle has a transponder",
  },
  {
    name: "Plate Rate",
    value: "plateRead",
    helpText: "Rate provided based plate enrollment with the agency ",
  },
  {
    name: "Cash Rate",
    value: "cashRate",
    helpText: "Rate if the plaza accepts cash",
  },
  {
    name: "Negotiated Rate",
    value: "negotiatedRate",
    helpText: "Rates used if the Verra has a negotiated rate",
  },
  {
    name: "HOV Rate",
    value: "hovRate",
    helpText: "Rates based on the passenger occupancy ",
  },
  {
    name: "Equity Rate",
    value: "equityRate",
    helpText: "Rates applied if equity is enabled to that vehicle",
  },
];

export const userHeaderNames = [
  { name: "Username", value: "username", helpText: "User's username" },
  { name: "Email", value: "email", helpText: "User's email address" },
  { name: "Phone", value: "phone", helpText: "User's phone number" },
  { name: "Role", value: "role", helpText: "User's role in the system" },
  { name: "Enabled", value: "enabled", helpText: "User's account status" },
  {
    name: "Create Date",
    value: "createDate",
    helpText: "Account creation date",
  },
  {
    name: "Last Modified Date",
    value: "lastModifiedDate",
    helpText: "Date of last modification",
  },
];

export const agencyHeaders = [
  { name: "Name", value: "name", helpText: "Agency Name" },
  {
    name: "Status",
    value: "statusName",
    helpText: "Agency Status: Active, Inactive",
  },
  {
    name: "Currency",
    value: "currency",
    helpText: "Currency used by the agency",
  },
  {
    name: "Country",
    value: "country",
    helpText: "Country where the agency is located",
  },
  {
    name: "State",
    value: "state",
    helpText: "State where the agency is located",
  },
  {
    name: "Charge Type",
    value: "chargeType",
    helpText: "Type of charge applied by the agency",
  },
  { name: "Max HOV", value: "chargeHov", helpText: "Maximum HOV charge" },
  {
    name: "Services",
    value: "servicesString",
    helpText: "Services provided by the agency: Toll, RUC, VMPass",
  },
  { name: "Time Zone", value: "timeZone", helpText: "Time zone of the agency" },
];

export const tollImportGroupedHeaders = [
  {
    name: "Invoke ID",
    value: "invokeId",
    helpText: "Unique identifier for the invocation",
  },
  {
    name: "Total Files Ran",
    value: "countFilesRan",
    helpText: "Total number of files processed",
  },
  {
    name: "Total Success",
    value: "totalSuccess",
    helpText: "Total number of successful operations",
  },
  {
    name: "Total Failed",
    value: "totalFailed",
    helpText: "Total number of failed operations",
  },
  {
    name: "Total Newly Created",
    value: "totalNewlyCreated",
    helpText: "Total number of newly created records",
  },
  {
    name: "Invoke Date Time",
    value: "invokeDateTime",
    helpText: "Date and time when the invocation was made",
  },
  {
    name: "Invoked User",
    value: "invokedUser",
    helpText: "User who initiated the invocation",
  },
];

export const tollImportMetadataHeaders = [
  { name: "Filename", value: "fileName" },
  { name: "Success Count", value: "successCount" },
  { name: "Failed Count", value: "failureCount" },
  { name: "Newly Created Count", value: "newlyCreatedCount" },
  { name: "Invoke Date Time", value: "invokeDateTime" },
  { name: "Process Completion Date Time", value: "processCompletedDateTime" },
  { name: "Invoke User", value: "invokedUser" },
];

export const historicalDataHeaders = [
  { name: "Updated By", value: "updatedBy" },
  { name: "Updated Timestamp", value: "timestamp" },
  { name: "Operation", value: "operationType" },
  { name: "Updates", value: "updatesString" },
];
