import moment from "moment";
import momentTZ from "moment-timezone";
import * as turf from "@turf/turf";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

import {
  agencyTollHeaderNames,
  gnssTollHeaderNames,
  rateHeaderNames,
  rucHeaderNames,
  transactionHeaderNames,
  vehicleHeaders,
  vehicleTracesReportHeaderNames,
  vehicleActivityHeaders,
  vehicleMessagesHeaders,
} from "../assets";

// Takes in the api locations and returns geojson
export const geofencesToGeoJSON = (geofenceData: any[]) => {
  return {
    type: "FeatureCollection",
    features: geofenceData
      .map((geofence: any) => {
        return geofenceToGeoJSON(geofence);
      })
      .sort((a: any, b: any) => {
        return a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase());
      }),
  };
};

// Takes in a single location and returns a feature geojson object
export const geofenceToGeoJSON = (geofence: any, name?: string) => {
  if (!geofence) return;

  const coords = getGeometryCoords(geofence);
  const centerPoint = getPopupCoordinates(coords);

  return {
    ...geofence,
    type: "Feature",
    id: geofence._id || geofence.id,
    agencyId: geofence?.properties?.agencyId || geofence?.agencyId,
    agencyName: geofence?.properties?.agencyName || geofence?.agencyName,
    center: centerPoint,
    locationType: geofence?.properties?.locationType || geofence?.locationType,
    status: geofence?.properties?.status || geofence?.status,
    notes: geofence?.properties?.notes || geofence?.notes || "",
    plazaId: geofence?.properties?.plazaId || geofence?.plazaId || 0,
    roadName: geofence?.properties?.roadName || geofence?.roadName || "",
    verified: geofence?.properties?.verified || geofence?.verified || false,
    geometry: {
      type: "Polygon",
      coordinates: coords,
    },
    properties: {
      ...geofence?.properties,
      agencyId: geofence?.properties?.agencyId || geofence?.agencyId,
      agencyName: geofence?.properties?.agencyName || geofence?.agencyName,
      center: centerPoint,
      eventActions:
        geofence?.eventActions || geofence?.properties?.eventActions || [],
      lockStatus: geofence?.properties?.hasOwnProperty("lockStatus")
        ? geofence?.properties?.lockStatus
        : geofence?.properties?.locationType === "RUC",
      locationSubType: geofence?.properties?.locationSubType
        ? geofence?.properties?.locationSubType
        : geofence?.properties?.locationType === "TOLL"
        ? "POINT"
        : undefined,
      name: name || geofence?.name || geofence.properties?.name,
      notes: geofence?.properties?.notes || geofence?.notes || "",
      schedules: geofence?.schedules || geofence.properties?.schedules || [],
      status: geofence?.properties?.status || geofence?.status,
      verified: geofence?.properties?.verified || geofence?.verified || false,
    },
    // place_name and text are used for location search
    place_name: `${geofence?.name},  <strong>${
      geofence?.properties?.locationType
    }</strong>: ${geofence?.properties?.plazaId || geofence?.plazaId || 0}`,
    text: geofence?.name,
  };
};

// Extract the coordinates from the given geofence
export const getGeometryCoords = (geofence: any) => {
  if (
    geofence?.geometry?.coordinates?.length ||
    (geofence?.geometry?.center && geofence?.geometry?.radius)
  ) {
    if (geofence?.geometry?.center && geofence?.geometry?.radius) {
      const { center, radius } = geofence.geometry;

      // Using turf circle we can create a geojson polygon that plays nice with mongodb geospatial
      return turf.circle(center, radius).geometry.coordinates;
    }

    if (geofence.geometry.coordinates.length > 1) {
      return [geofence.geometry.coordinates];
    }

    return geofence.geometry.coordinates;
  } else {
    return [];
  }
};

// Returns the center point (or close to if strange shape) of a geofence to display marker/popup
export const getPopupCoordinates = (coordinates: any) => {
  // Using turf.js to calculate popup coords of a geofence
  const polygon = turf.polygon(coordinates);
  const pointOnSurface = turf.pointOnFeature(polygon);
  return pointOnSurface.geometry.coordinates;
};

// Shape traces for the report page
export const shapeTraceData = (traceData: any) => {
  return traceData.map((trace: any) => {
    // If the delayReceivedTrace is greater than the latency limit, color the row red
    const rowColor =
      trace.data[0].delayReceivedTrace >
      parseInt(process.env.REACT_APP_TRACE_LATENCY_LIMIT) * 1000
        ? "#ffdad6" // Red
        : null;

    return {
      ...trace.data[0],
      _id: trace._id,
      vin: trace.vin,
      dataSource: trace.data[0].dataSource || "Provider",
      rowColor,
      latency: trace.data[0].delayReceivedTrace
        ? convertMillisecondsToTime(trace.data[0].delayReceivedTrace)
        : null,
      timeZoneName: getFriendlyTimezoneName(
        trace.data[0].timeZone,
        trace.data[0].timeStampLocal,
        "long"
      ),
      actionButton: {
        actionFunction: () =>
          window.open(
            `/?vin=${trace.vin}&traceDate=${
              trace.data[0].timeStampLocal.split("T")[0]
            }&traceId=${trace._id}`,
            "_blank"
          ),
        icon: faLocationDot,
        tooltip: "View on map",
      },
    };
  });
};

export const shapeTripData = (tripData: any) => {
  const data = [];
  for (const trip of tripData) {
    // Sort the trip traces by latest timestamp
    trip.traces.sort(
      (a: any, b: any) => b.data[0].timestamp - a.data[0].timestamp
    );

    data.push({
      ...trip,
      traces: trip.traces.map((trace: any) => ({
        ...trace.data[0],
        _id: trace._id,
        vin: trace.vin,
        dataSource: trace.data[0].dataSource || "Provider",
        latency: trace.data[0].delayReceivedTrace
          ? convertMillisecondsToTime(trace.data[0].delayReceivedTrace)
          : null,
        timeZoneName: getFriendlyTimezoneName(
          trace.data[0].timeZone,
          trace.data[0].timeStampLocal,
          "long"
        ),
      })),
    });
  }

  return data;
};

export const filterTripData = (
  tripData: any,
  unfilteredTraceId?: string | null
) => {
  if (!tripData.length) return [];

  // Sort the data by latest timestamp
  tripData.sort((a: any, b: any) => b.timestamp - a.timestamp);

  // Filter out duplicate lat/lon values in order to keep latest trace
  const filteredTraceData = [];
  let prevLat = 0;
  let prevLon = 0;

  let unfilteredTrace = null;

  // Sorted data, grab the start point for rendering
  const startPoint = tripData[tripData.length - 1];

  for (const data of tripData) {
    const { latitude, longitude } = data;

    // If unfilteredTraceId is provided, keep track of it to insert at the end instead so it renders above any stacked traces
    if (data._id === unfilteredTraceId) {
      unfilteredTrace = data;
      continue;
    }

    // Force insert the start point
    if (data._id === startPoint._id) {
      filteredTraceData.push(data);
      continue;
    }

    if (
      latitude.toFixed(4) !== prevLat.toFixed(4) ||
      longitude.toFixed(4) !== prevLon.toFixed(4)
    ) {
      filteredTraceData.push(data);
      prevLat = latitude;
      prevLon = longitude;
    }
  }

  // Insert the unfiltered trace second to last in the array to not overlap the start or end point
  if (unfilteredTrace) {
    // Insert the unfiltered trace second to last in the array to not overlap the start or end point
    filteredTraceData.splice(filteredTraceData.length - 1, 0, unfilteredTrace);
  }

  return filteredTraceData.map((trace: any) => ({
    ...trace,
    _id: trace._id,
    vin: trace.vin,
    dataSource: trace.dataSource || "Provider",
    latency: trace.delayReceivedTrace
      ? convertMillisecondsToTime(trace.delayReceivedTrace)
      : null,
    timeZoneName: getFriendlyTimezoneName(
      trace.timeZone,
      trace.timeStampLocal,
      "long"
    ),
  }));
};

// Formats the given date string to match how we store in DB
export const formatDateToAPI = (dateString: string) => {
  if (!dateString) return undefined;

  return new Date(dateString).toISOString();
};

// Given a report type return the table header values
export const getHeaderNames = (reportType: string) => {
  switch (reportType) {
    case "ruc":
      return rucHeaderNames;
    case "transaction":
      return transactionHeaderNames;
    case "gnssToll":
      return gnssTollHeaderNames;
    case "agencyToll":
      return agencyTollHeaderNames;
    case "vehicleTraces":
      return vehicleTracesReportHeaderNames;
    case "rates":
      return rateHeaderNames;
    case "vehicleStatuses":
      return vehicleHeaders;
    case "vehicleActivity":
      return vehicleActivityHeaders;
    case "vehicleMessages":
      return vehicleMessagesHeaders;
    default:
  }
};

// Given table headers and table data, shape for the react-csv extract option
export const shapeCSVData = (headers: any[], dataSource: any[]) => {
  const cellHeaders: any[] = [];
  const cellData: any[] = [];

  if (dataSource.length) {
    for (let i = 0; i < headers.length; i++) {
      if (headers[i].exportable === false) continue;

      cellHeaders.push({
        label: headers[i].name,
        key: `dataSource.${headers[i].value}`,
      });
    }
    for (let i = 0; i < dataSource.length; i++) {
      cellData.push({
        dataSource: dataSource[i],
      });
    }
  }
  return { cellHeaders, cellData };
};

export const getCSVFileName = (
  reportType: string,
  filterValues: any
): string => {
  const fileNameParts: string[] = [];

  for (const key in filterValues) {
    if (filterValues.hasOwnProperty(key)) {
      const keyValue = filterValues[key];
      // Find the name in the options array if dropdown
      if (keyValue?.type === "dropdown") {
        const option =
          keyValue.options.find(
            (option: any) => option.value === keyValue.value
          )?.name || undefined;
        fileNameParts.push(option);
        continue;
      }
      if (keyValue?.value) {
        fileNameParts.push(keyValue.value);
      }
    }
  }

  return `${reportType}_${fileNameParts.join("_")}`;
};

// Given TCA Matching table headers and table data, shape for the react-csv extract option
export const shapeMatchingTollCSV = (
  headersDataSource1: any[],
  headersDataSource2: any[],
  dataSource1: any[],
  dataSource2: any[]
): any => {
  const cellHeaders: any[] = [];
  const cellData: any[] = [];

  if (dataSource1.length) {
    for (let i = 0; i < headersDataSource1.length; i++) {
      cellHeaders.push({
        label: headersDataSource1[i].name,
        key: `dataSource1.${headersDataSource1[i].value}`,
      });
    }

    cellHeaders.push({ label: "", key: "" });

    for (let i = 0; i < headersDataSource2.length; i++) {
      cellHeaders.push({
        label: headersDataSource2[i].name,
        key: `dataSource2.${headersDataSource2[i].value}`,
      });
    }

    for (let i = 0; i < dataSource1.length; i++) {
      cellData.push({
        dataSource1: dataSource1[i],
        dataSource2: dataSource2[i],
      });
    }
  }
  return { cellHeaders, cellData };
};

// Determines the color a vehicle trace on the map should be
export const getVehicleTraceColor = ({
  startPoint,
  endPoint,
  traceData,
}: {
  startPoint: boolean;
  endPoint: boolean;
  traceData: any;
}) => {
  const { delayReceivedTrace, tollingSpeed, dataSource } = traceData;

  if (startPoint === true) {
    return "#84ca50"; // Green
  } else if (endPoint === true) {
    return "#e60000"; // Red
  } else if (dataSource === "Simulator") {
    return "#da70d6"; // Purple
  } else if (dataSource === "DRP") {
    return "#b7bac5"; // Grey
  } else if (
    delayReceivedTrace >
    parseInt(process.env.REACT_APP_TRACE_LATENCY_LIMIT) * 1000
  ) {
    if (tollingSpeed === "slow") {
      return "#ff4d4d"; // Light Red
    } else if (tollingSpeed === "fast") {
      return "#ff1a1a"; // Dark Red
    }
  } else if (tollingSpeed === "slow") {
    return "#1e90ff"; // Light Blue
  } else if (tollingSpeed === "fast") {
    return "#1560bd"; // Dark Blue
  } else {
    return "#f07d02"; // Orange
  }
};

// Takes in a UTC date and converts to the users time zone
export const convertToUserTimezone = (utcDate: string, timezone: string) => {
  // Parse the date-time string and adjust it to the specified timezone
  const momentDateTime = momentTZ.tz(utcDate, timezone);

  return momentDateTime.format();
};

// Given a time string return in 24hr format
export const getTwentyFourHourTime = (timeString: string) => {
  let [hour, minutes]: any[] = timeString.slice(0, 5).split(":");
  const isPM = timeString.includes("PM");

  if (hour < 10) {
    hour = hour.padStart(2, "0");
  }

  if (isPM) hour = `${parseInt(hour) + 12}`;
  if (isPM && hour === "24") hour = "12";
  if (!isPM && hour === "12") hour = "00";

  return `${hour}:${minutes}`;
};

// Given 24hr time stirng return in a 12hr format
export const convertTwentyFourHourTime = (timeString: string, name: string) => {
  let [hour, minutes]: any[] = timeString.split(":");
  const isPM = parseInt(hour) >= 12;

  if (isPM) hour = `${parseInt(hour) - 12}`.padStart(2, "0");
  if (parseInt(hour) === 0) hour = "12";

  return `${hour}:${minutes}:${name === "timeOfDayEnd" ? "59" : "00"} ${
    isPM ? "PM" : "AM"
  }`;
};

// Takes a timestring and makes it UI user friendly
export const convertToFriendlyTimeFormat = (timeString: string) => {
  let [hour, minutes]: any[] = timeString.split(":");
  const isPM = timeString.includes("PM");

  return `${parseInt(hour)}:${minutes}${isPM ? "PM" : "AM"}`;
};

// Create a date string that matches format of UTC date string
export const getUTCDateString = (dateString: string) => {
  const date = new Date(dateString);
  const utcDateString = date.toUTCString();
  if (dateString) {
    const year = date.getUTCFullYear(),
      month = `${date.getUTCMonth() + 1}`.padStart(2, "0"),
      day = `${date.getUTCDate()}`.padStart(2, "0"),
      time = utcDateString.slice(17, 22);

    return `${year}-${month}-${day}T${time}`;
  }
  return "";
};

// Convert a utc date string
export const convertUTCDateString = (dateString: string) => {
  let convertedDate = `${new Date(dateString)}`;
  return moment
    .utc(`${convertedDate.slice(0, 24)} GMT+0000 (Coordinated Universal Time)`)
    .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
};

// Convert milliseconds to hh:mm:ss format with leading zeros
export const convertMillisecondsToTime = (milliseconds: number) => {
  const hours = Math.floor(milliseconds / 3600000);
  const minutes = Math.floor((milliseconds % 3600000) / 60000);
  const seconds = Math.floor((milliseconds % 60000) / 1000);

  // If any of the values are negative, return 00:00:00
  if (hours < 0 || minutes < 0 || seconds < 0) return "00:00:00";

  // If the milliseconds are less than 1 second, return 00:00:01
  if (milliseconds < 1000) return "00:00:01";

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

// Sort the schedule object in a geofence
export const scheduleSorting = (schedules: any[]) => {
  let sortedSchedules: any[] = cloneDeep(schedules);
  if (schedules?.length) {
    sortedSchedules.sort((a: any, b: any) => {
      return (
        a["vehicleClass"]
          ?.toLowerCase()
          ?.localeCompare(b["vehicleClass"]?.toLowerCase()) ||
        a["dayOfWeekStart"] - b["dayOfWeekStart"] ||
        getTwentyFourHourTime(a["timeOfDayStart"])?.localeCompare(
          getTwentyFourHourTime(b["timeOfDayStart"])
        )
      );
    });

    sortedSchedules.forEach((schedule: any) => {
      schedule.charges.sort((a: any, b: any) => {
        return a["passengersCount"] - b["passengersCount"];
      });
    });
  }

  return sortedSchedules;
};

// Generate a simple rectange geojson object using lon/lat,width,height params
export const generateRectangle = ({
  lon,
  lat,
  width,
  height,
}: {
  lon: string | number;
  lat: string | number;
  width: number;
  height: number;
}) => {
  const oneDegLat = 364000;
  const oneDegLon = 288200;

  lon = parseFloat(`${lon}`);
  lat = parseFloat(`${lat}`);

  const heightToLat = height / oneDegLat;
  const widthToLon = width / oneDegLon;

  //! x1,y1 ------------- x2,y1
  //!   |                   |
  //!   |                   |
  //! x1,y2 ------------- x2,y2
  const x1 = lon + widthToLon / 2;
  const y1 = lat + heightToLat / 2;
  const x2 = lon - widthToLon / 2;
  const y2 = lat - heightToLat / 2;

  return {
    features: [
      {
        id: uuidv4(),
        locationType: "TOLL", // Default to TOLL to show on map when creating
        type: "Feature",
        properties: {
          locationType: "TOLL", // Default to TOLL to show on map when creating
        },
        geometry: {
          coordinates: [
            [
              [x1, y1],
              [x1, y2],
              [x2, y2],
              [x2, y1],
              [x1, y1],
            ],
          ],
          type: "Polygon",
        },
      },
    ],
  };
};

// Shifting the geojson coordinates by a small amount to avoid overlapping when cloning
export const shiftCloneGeofence = (geofence: any) => {
  const clonedGeofence = cloneDeep(geofence);
  const coordinates = clonedGeofence.geometry.coordinates[0];

  // Determine the height and width of the geofence using turf
  const turfBbox = turf.bbox(geofence);
  const turfBboxWidth = turfBbox[2] - turfBbox[0];
  const turfBboxHeight = turfBbox[3] - turfBbox[1];

  // Shift the coordinates by 2.5x the height and width
  const shiftedLon = turfBboxWidth / 2.5;
  const shiftedLat = turfBboxHeight / 2.5;

  const shiftedCoordinates = coordinates.map((coordinate: any) => {
    const [lon, lat] = coordinate;
    return [lon + shiftedLon, lat + shiftedLat];
  });

  clonedGeofence.geometry.coordinates[0] = shiftedCoordinates;

  return clonedGeofence;
};

export const handleViewOnGoogle = (centerPoint: number[]) => {
  if (centerPoint.length) {
    const [lon, lat] = centerPoint;
    window.open(`https://www.google.com/maps?q=${lat},${lon}`);
  }
};

// Find all entry points for a given DISTANCE-EXIT geofence
export const findAllEntryPoints = ({
  rawGeofenceData,
  selectedGeofence,
}: {
  rawGeofenceData: any;
  selectedGeofence: any;
}) => {
  const { roadName, agencyId, center } = selectedGeofence.properties;

  return rawGeofenceData.features
    .filter((geofence: any) => {
      return (
        geofence.properties.locationSubType === "DISTANCEENTRY" &&
        geofence.properties.roadName === roadName &&
        geofence.properties.agencyId === agencyId
      );
    })
    .sort((a: any, b: any) => {
      // Sort by distance from selected geofence
      const aDistance = turf.distance(center, a.center);
      const bDistance = turf.distance(center, b.center);
      return bDistance - aDistance;
    })
    .map((entryPoint: any, index: number) => ({
      name: entryPoint.properties.name,
      id: entryPoint.id,
      entryPointOrder: index + 1,
    }));
};

export const sortGridData = ({
  unsortedData,
  sortValue,
  ascending,
}: {
  unsortedData: any[];
  sortValue: string;
  ascending: boolean;
}) => {
  return [...unsortedData].sort((a: any, b: any) => {
    if (!a[sortValue]) return ascending ? -1 : 1;
    if (!b[sortValue]) return ascending ? 1 : -1;

    // Since dayOfWeek is string of day name, we need to map it to a number to sort
    if (sortValue.includes("dayOfWeek")) {
      const dayOfWeekMap: { [key: string]: number } = {
        Sunday: 0,
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
      };
      return ascending
        ? dayOfWeekMap[a[sortValue as keyof typeof dayOfWeekMap]] -
            dayOfWeekMap[b[sortValue as keyof typeof dayOfWeekMap]]
        : dayOfWeekMap[b[sortValue as keyof typeof dayOfWeekMap]] -
            dayOfWeekMap[a[sortValue as keyof typeof dayOfWeekMap]];
    }

    if (typeof a[sortValue] === "string")
      return ascending
        ? a[sortValue]
            ?.toLowerCase()
            ?.localeCompare(b[sortValue]?.toLowerCase())
        : b[sortValue]
            ?.toLowerCase()
            ?.localeCompare(a[sortValue]?.toLowerCase());

    if (typeof a[sortValue] === "number")
      return ascending
        ? a[sortValue] - b[sortValue]
        : b[sortValue] - a[sortValue];

    return ascending
      ? a[sortValue] - b[sortValue]
      : b[sortValue] - a[sortValue];
  });
};

export const getPointMeasurements = ({
  startPoint,
  endPoint,
}: {
  startPoint: number[];
  endPoint: number[];
}) => {
  const from = turf.point(startPoint);
  const to = turf.point(endPoint);

  // Get distance in feet from miles
  let distance = turf.distance(from, to, { units: "miles" });
  distance = distance * 5280;

  // Get the bearing of the two points
  let course = turf.bearing(from, to);
  // Course should be between 0 and 360
  if (course < 0) course += 360;

  // Round distance and course to 2 decimal places
  distance = Math.round(distance * 100) / 100;
  course = Math.round(course * 100) / 100;
  return { distance, course };
};

export const trimStringEndWhiteSpace = (string: string) => {
  if (string && string.length) {
    return string.trim();
  } else {
    return string;
  }
};

export const getFriendlyTimezoneName = (
  programmaticName: string,
  date: Date,
  timeZoneNameType:
    | "long"
    | "short"
    | "shortOffset"
    | "longOffset"
    | "shortGeneric"
    | "longGeneric"
) => {
  if (!programmaticName) return "";
  if (!date) return programmaticName;
  return new Intl.DateTimeFormat("default", {
    timeZone: programmaticName,
    timeZoneName: timeZoneNameType || "long",
  })
    .formatToParts(new Date(date))
    .find(({ type }) => type === "timeZoneName").value;
};

// Given a time string, adjust for the UTC offset difference and return a date
export const adjustTimeForUTCOffset = (
  timeString: string,
  timeZone: string
) => {
  const [hours, minutes] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);

  const offset = momentTZ.tz(timeZone).utcOffset() / 60;
  date.setHours(date.getHours() - offset);

  return date;
};
